import { useRef, useEffect } from 'react'
import Head from 'next/head'
import ResizeObserver from 'resize-observer-polyfill';
import { SWRConfig } from 'swr';

import '../styles/open-sans.css'
import '../styles/lato.css'
import '../styles/global.css'

export default function MyApp({ Component, pageProps }) {
  const swrOptions = {
    refreshInterval: 0,
    revalidateOnFocus: false,
    fetcher: (...args) => fetch(...args).then(res => res.json())
  }

  const resizeObserver = useRef()

  useEffect(() => {
    const nextApp = document.querySelector("#__next")
    function documentSize() {
      const height = nextApp.clientHeight
      window.parent.postMessage(['setHeight', height], '*')
    }
    if (!resizeObserver.current) {
      resizeObserver.current = new ResizeObserver(documentSize).observe(nextApp)
    }
    documentSize()
  }, [])

  return <>
    <Head>
      <title>Unikitout Embedded</title>
      <link rel="icon" href="/favicon.ico" />
      <script
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WS6GMB58RW');`,
    }}
  />
    </Head>
    <div className="max-w-screen text-gray-700 font-sans">
      <SWRConfig value={swrOptions}>
        <Component {...pageProps} />
      </SWRConfig>
    </div>
    <div>
    <noscript
    dangerouslySetInnerHTML={{
      __html: `<iframe src="https://www.googletagmanager.com/gtag/js?id=G-WS6GMB58RW" height="0" width="0" style="display: none; visibility: hidden;" />`,
    }}
  />
    </div>
  </>
}
